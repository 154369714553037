
import { defineComponent } from 'vue';

/**
 * Атомарний компонент іконки видалення.
 * Найпростіша, неподільна частина інтерфейсу.
 * @displayName ATrashIcon
 */
export default defineComponent({
  name: 'ATrashIcon'
});
